<template>
  <div class="d-flex h-100 flex-column justify-content-between">
    <div>
      <p>Vehículos</p>
      <div
        v-show="!hasCurrent && hasVehicles && !isLoading"
        class="message info"
      >
        <font-awesome-icon icon="incidence" />
        <p>
          El mensajero no está en linea y no pudimos obtener su vehículo activo
        </p>
      </div>
      <loading-spinner v-show="isLoading" class="mt-2" />
      <div v-show="!isLoading" class="container-vehicles">
        <div v-for="vehicle in vehicles" :key="vehicle.id">
          <div class="d-flex align-items-center border-bottom p-3">
            <b-form-radio
              v-model="currentVehicle"
              class="mr-2"
              size="lg"
              :value="vehicle.id"
            >
            </b-form-radio>

            <div class="vehicle-icon rounded-lg">
              <font-awesome-icon :icon="defineVehicleIcon(vehicle.type)" />
            </div>
            <div class="ml-3">
              <div class="text">{{ vehicleConstants.get[vehicle.type] }}</div>
              <div class="text info mt-1">{{ vehicle.plateNo }}</div>
            </div>
            <div
              class="d-flex justify-content-center align-items-center action ml-auto"
            >
              <b-dropdown
                v-if="
                  $can(driverAccess.MANAGE_VEHICLES, driverAccess.moduleName)
                "
                class="dropdown"
                variant="link"
                dropleft
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <font-awesome-icon id="icon" icon="ellipsis-h" />
                </template>
                <b-dropdown-item @click.stop="showVehicleModal(vehicle)">
                  Editar
                </b-dropdown-item>
                <b-dropdown-item @click.stop="deleteVehicle(vehicle.id)">
                  Eliminar
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!hasVehicles && !isLoading" class="text-center">
      <p>El mensajero no tiene vehículos registrados 🛵</p>
    </div>
    <z-button
      v-if="$can(driverAccess.MANAGE_VEHICLES, driverAccess.moduleName)"
      class="z-action mt-3"
      @click="showVehicleModal()"
    >
      Añadir
    </z-button>
    <create-vehicle-modal
      :selected-vehicle="selectedVehicle"
      :edit="isEditingVehicle"
      :show.sync="showModal"
      @success-create-vehicle="createdVehicle"
      @success-edit-vehicle="editedVehicle"
      @error-create-vehicle="errorOnRequest"
    />
    <div
      v-show="hasErrors || isSuccess"
      class="message mt-3"
      :class="{ error: hasErrors, success: isSuccess }"
    >
      <font-awesome-icon :icon="hasErrors ? 'times-circle' : 'check'" />
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import DriverAccess from "@/constants/access/drivers";
import VehicleConstants from "@/constants/drivers/vehicles";
import CreateVehicleModal from "./CreateVehicleModal";

export default {
  name: "CardVehicles",

  components: {
    CreateVehicleModal
  },

  data() {
    return {
      driverAccess: DriverAccess,
      vehicleConstants: VehicleConstants,
      isLoading: false,
      message: "",
      hasErrors: false,
      isSuccess: false,
      vehicles: [],
      currentVehicle: "",
      showModal: false,
      isEditingVehicle: false,
      selectedVehicle: null
    };
  },

  computed: {
    hasVehicles() {
      return this.vehicles.length > 0;
    },
    hasCurrent() {
      return this.currentVehicle !== "";
    }
  },

  beforeMount() {
    this.getVehicles();
  },

  methods: {
    getVehicles() {
      this.isLoading = true;
      this.$store
        .dispatch("drivers/getVehicles", this.$route.params.id)
        .then(res => {
          this.vehicles = res.vehicles;
          this.currentVehicle = res.current ?? "";
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },

    defineVehicleIcon(type) {
      switch (type) {
        case VehicleConstants.NUM_MOTO:
          return "zubut-motorcycle";
        case VehicleConstants.NUM_CAR:
          return "zubut-car";
        case VehicleConstants.NUM_ECO:
          return "zubut-bike";
        default:
          return "zubut-motorcycle";
      }
    },

    deleteVehicle(vehiclesId) {
      this.$store
        .dispatch("drivers/deleteVehicle", vehiclesId)
        .then(() => {
          this.message = "Vehículo eliminado con exito";
          this.isSuccess = true;
          setTimeout(() => {
            this.isSuccess = false;
          }, 3000);
          this.vehicles = this.vehicles.filter(v => v.id !== vehiclesId);
        })
        .catch(err => {
          this.errorOnRequest(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showVehicleModal(vehicle) {
      if (vehicle != null) {
        this.selectedVehicle = vehicle;
      }
      this.isEditingVehicle = vehicle != null;
      this.showModal = true;
    },

    createdVehicle(vehicle) {
      this.message = "Vehículo creado con exito";
      this.isSuccess = true;
      setTimeout(() => {
        this.isSuccess = false;
      }, 3000);
      this.vehicles.push(vehicle);
    },

    editedVehicle(vehicle) {
      this.message = "Vehículo actualizado con exito";
      this.isSuccess = true;
      setTimeout(() => {
        this.isSuccess = false;
      }, 3000);
      this.vehicles = this.vehicles.map(v => {
        if (v.id === vehicle.id) {
          v = vehicle;
        }
        return v;
      });
    },

    errorOnRequest(err) {
      this.message = err;
      this.hasErrors = true;
      setTimeout(() => {
        this.hasErrors = false;
      }, 5000);
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
}

p:first-child {
  font-size: 14px;
  font-weight: 500;
}

.text {
  font-size: 12px;
  color: $black;

  &.info {
    color: $dim-gray;
  }
}

.container-vehicles {
  overflow: auto;
}

.vehicle-icon {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: darken($white-smoke, 5%);
  font-size: 2rem;
}

.action {
  width: 40px;
  height: 40px;
  font-size: 16px;

  #icon {
    color: $black;
  }

  &:hover {
    cursor: pointer;
  }
}

.z-action {
  font-weight: 400;
  height: 40px;
  width: 100px;
}

.message {
  display: flex;
  padding: 0.5rem 1rem;
  font-size: 11px;
  align-items: center;
  font-weight: 500;

  p {
    padding: 0;
    margin: 0;
    margin-left: 1rem;
  }

  &.error {
    background-color: $tutu;
    color: $mexican-red;
  }

  &.success {
    background-color: $rice-flower;
    color: $forest-green;
  }

  &.info {
    background-color: rgba($zubut-blue, 0.12);
    color: $zubut-blue;
  }
}

@media only screen and (min-width: 768px) {
  .container-vehicles {
    max-height: 165px;
  }
}
</style>
