<template>
  <div>
    <skeleton-box v-if="loading" class="rounded" height="100px" width="100px" />
    <z-profile-image v-else type="driver" :size="size" :image="profileImage" />
  </div>
</template>

<script>
import ZProfileImage from "@/app/components/ZProfileImage";
import SkeletonBox from "@zubut/common/src/components/SkeletonBox";

export default {
  name: "DriverImage",

  components: {
    SkeletonBox,
    ZProfileImage
  },

  props: {
    driverId: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "sm",
      validator: val => ["sm", "lg"].indexOf(val) > -1
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    profileImage() {
      return "img/driver-avatar.png";
    }
  }
};
</script>
