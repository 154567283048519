
















































import Vue from "vue";
import ZSelect from "@zubut/common/src/components/ZSelect.vue";
import ZModal from "@zubut/common/src/components/ZModal.vue";
import VehicleType from "@zubut/common/src/constants/vehicles/type";

export default Vue.extend({
  name: "CreateVehicleModal",

  components: {
    ZSelect,
    ZModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    selectedVehicle: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      vehicle: {
        plateNo: "",
        type: ""
      },
      loading: false,
      vehicleOptions: VehicleType.options
    };
  },

  computed: {
    isFormValid(): boolean {
      return this.isValidVehicleType && this.isValidPlateNo;
    },
    isValidVehicleType(): boolean {
      return (
        VehicleType.options.find(op => op.value === this.vehicle.type) != null
      );
    },
    isValidPlateNo(): boolean {
      return (
        this.vehicle.plateNo.length > 0 && this.vehicle.plateNo.length <= 10
      );
    },
    modalTitle(): string {
      if (this.edit) {
        return "Actualizar Vehículo";
      }
      return "Crear Vehículo";
    },
    plateNoState(): boolean | null {
      return this.vehicle.plateNo.length === 0 ? null : this.isValidPlateNo;
    }
  },

  watch: {
    show(newVal) {
      if (newVal === false) {
        this.reset();
      } else if (newVal === true && this.edit === true) {
        this.vehicle = { ...this.selectedVehicle };
      }
    }
  },

  methods: {
    createVechicle() {
      this.loading = true;
      const driverId = this.$route.params.id;
      const data = {
        driverId,
        plateNo: this.vehicle.plateNo,
        type: this.vehicle.type
      };
      this.$store
        .dispatch("drivers/createVehicle", { driverId, data })
        .then(vehicle => {
          this.$emit("success-create-vehicle", vehicle);
        })
        .catch(err => {
          this.$emit("error-create-vehicle", err);
        })
        .finally(() => {
          this.$emit("update:show", false);
          this.loading = false;
        });
    },

    editVehicle() {
      this.loading = true;
      const vehicleId = this.selectedVehicle.id;
      const driverId = this.selectedVehicle.driverId;
      const data = {
        plateNo: this.vehicle.plateNo,
        type: this.vehicle.type,
        driverId
      };
      this.$store
        .dispatch("drivers/editVehicle", { vehicleId, data })
        .then(vehicle => {
          this.$emit("success-edit-vehicle", vehicle);
        })
        .catch(err => {
          this.$emit("error-create-vehicle", err);
        })
        .finally(() => {
          this.$emit("update:show", false);
          this.loading = false;
        });
    },

    handleCreateVechicle() {
      if (this.edit) {
        this.editVehicle();
      } else {
        this.createVechicle();
      }
    },

    reset() {
      this.vehicle = {
        plateNo: "",
        type: ""
      };
    }
  }
});
