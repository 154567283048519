<!-- todo: delete this component (?) -->
<template>
  <div class="d-flex h-100 flex-column justify-content-sm-between">
    <p>Calificación</p>

    <div>
      <div class="rate-graph rounded">
        <div class="rounded" :style="{ width: `${rate}%` }"></div>
      </div>
      <p class="text info">
        {{ hasRatings ? `${rate}%` : "Sin calificación" }}
      </p>
    </div>

    <div class="text mt-2">Miembro desde {{ clientSince }}</div>
  </div>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";

export default {
  name: "CardDetailRate",
  props: {
    createdAt: {
      type: String,
      required: false,
      default: null
    },
    ratings: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    clientSince() {
      if (this.createdAt) {
        return formatISO(this.createdAt);
      }
      return "";
    },

    hasRatings() {
      return this.ratings && this.ratings.length > 0;
    },

    rate() {
      let total = 0;
      if (this.hasRatings) {
        this.ratings.forEach(item => {
          const rate = parseInt(item.rate);
          if (rate === 1) {
            total += rate;
          }
        });
        return ((total / this.ratings.length) * 100).toFixed(0);
      }
      return total;
    }
  }
};
</script>

<style lang="scss" scoped>
p:first-child {
  font-size: 14px;
  font-weight: 600;
}

.text {
  font-size: 12px;

  &.info {
    color: $dim-gray;
    margin-top: auto;
  }
}

.rate-graph {
  height: 0.5rem;
  margin-bottom: 1rem;
  background-color: $gainsboro;
}

.rate-graph div {
  background: $zubut-blue;
  float: left;
  position: relative;
  height: 100%;
}
</style>
