<template>
  <div class="driver-detail-profile align-columns">
    <div class="left-side gap align-columns">
      <div class="card p-4 rounded-lg info">
        <card-detail-info
          component="drivers"
          :utype="user.userType"
          :name.sync="user.name"
          :phone.sync="user.phone"
          :email.sync="user.email"
          :driver-alpha.sync="user.alpha"
        >
          <template #profile-image>
            <driver-image size="lg" :driver-id="driverId" />
          </template>
        </card-detail-info>
      </div>

      <div
        v-if="$can(driverAccess.RESET_PASSWORD, driverAccess.moduleName)"
        class="card p-4 rounded-lg pass"
      >
        <card-detail-password component="drivers" />
      </div>

      <div
        v-if="$can(driverAccess.BLOCK_DRIVER, driverAccess.moduleName)"
        class="card p-4 rounded-lg account"
      >
        <card-detail-account component="drivers" :status.sync="user.status" />
      </div>
    </div>

    <div class="right-side gap align-columns">
      <div class="card p-4 rounded-lg rate">
        <card-detail-rate
          :ratings="user.ratings"
          :created-at="user.createdAt"
        />
      </div>

      <div class="card p-4 rounded-lg vehicle">
        <card-vehicles />
      </div>
    </div>
  </div>
</template>

<script>
import DriverAccess from "@/constants/access/drivers";
import CardDetailInfo from "@/app/components/DetailProfile/CardDetailInfo";
import CardDetailRate from "@/app/components/DetailProfile/CardDetailRate";
import CardDetailAccount from "@/app/components/DetailProfile/CardDetailAccount";
import CardDetailPassword from "@/app/components/DetailProfile/CardDetailPassword";
import DriverImage from "@/app/components/DriverImage";
import CardVehicles from "./CardVehicles";

export default {
  name: "DriverDetailProfile",

  components: {
    CardDetailAccount,
    CardDetailInfo,
    CardDetailRate,
    CardDetailPassword,
    CardVehicles,
    DriverImage
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      driverAccess: DriverAccess
    };
  },

  computed: {
    driverId() {
      return this.$route.params.id;
    }
  }
};
</script>

<style lang="scss" scoped>
$gap: 16px;

.card {
  color: $black;
  border: 1px solid $gainsboro;
  margin: 0;
}

.gap {
  gap: $gap;
}
.driver-detail-profile {
  gap: $gap;
}
.align-columns {
  display: flex;
  flex-direction: column;
}
.left-side {
  flex-grow: 1;
}
.right-side {
  flex-grow: 1.5;
}

@media only screen and (min-width: 768px) {
  .driver-detail-profile {
    flex-direction: row;
    gap: 24px;
  }
}
</style>
