<template>
  <div
    class="z-select"
    :class="{
      'z-select-has-value': value !== null && value !== '',
      'dropdown-error': isStateInvalid
    }"
  >
    <b-form-select
      :value="value"
      @input="$emit('input', $event)"
      class="form-input select"
      :disabled="disabled"
      :options="options"
    >
      <template slot="first">
        <option :value="null" disabled>
          {{ label }}
        </option>
      </template>
    </b-form-select>
    <label class="select-label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "ZSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, null],
      default: null
    },
    state: {
      type: [Boolean, null],
      default: null
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String],
      default: ""
    }
  },
  computed: {
    isStateInvalid() {
      return this.state === false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";
$font-size-sm: 12px;
$font-size-xs: 10px;

.z-select {
  position: relative;

  .select {
    border: 1px solid $link-water;
    border-radius: 6px;
    color: $comet;
    font-weight: 500;
    font-size: 12px;
    position: relative;

    &:focus {
      box-shadow: none;
    }
  }

  .select-label {
    display: block;
    position: absolute;
    bottom: 2.3rem;
    color: $comet;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    left: 12px;
    top: 6px;
    font-size: $font-size-sm;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 500;
    pointer-events: none;
  }
}

/* Float label when is has a value */
.z-select.z-select-has-value select + .select-label {
  z-index: 2;
  color: $comet;
  font-size: $font-size-xs;
  transform: translateY(-14px);
  transition: 0.2s ease-in-out transform;

  &:before {
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: white;
    left: 0px;
    top: 6px;
    z-index: -1;
    padding-left: 1px;
  }
}

.z-select.dropdown-error .select {
  border: 1px solid $medium-carmine;
  border-radius: 6px;
}
</style>
