<template>
  <div>
    <quick-message
      class="mb-2 mx-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="hasError ? 'error' : 'success'"
    />
    <loading-spinner v-if="loading" />
    <div v-else>
      <div class="mt-4 card p-4 rounded-lg">
        <quick-message
          v-if="missingBillingData"
          class="mb-3"
          show
          with-icon
          message="No tienes datos de facturación."
        />
        <b-form class="z-grid-page z-form">
          <div class="invoice-data">
            <p>Datos de facturación</p>
            <div class="inputs-wrapper">
              <z-input
                id="user-billing-name"
                v-model="mutableBillingData.billingName"
                label="Nombre Fiscal"
                :state="legalNameState"
                :disabled="disabled"
              />
              <z-input
                id="user-rfc"
                v-model="mutableBillingData.rfc"
                label="RFC"
                :state="rfcState"
                :disabled="disabled"
              />
              <z-input
                id="user-alternate-email-1"
                v-model="mutableBillingData.alternateEmail1"
                label="Dirección de correo adicional 1 (Opcional)"
                :state="alternateEmail1State"
                :disabled="disabled"
              />
              <z-input
                id="user-alternate-email-2"
                v-model="mutableBillingData.alternateEmail2"
                label="Dirección de correo adicional 2 (Opcional)"
                :state="alternateEmail2State"
                :disabled="disabled"
              />
            </div>
          </div>
          <div class="personal-data">
            <p>Dirección de facturación</p>
            <div class="inputs-wrapper">
              <div
                class="two-column-input d-flex justify-content-between flex-column flex-sm-row"
              >
                <z-input
                  id="user-billing-country"
                  v-model="mutableBillingData.country"
                  class="flex-grow-1 "
                  label="País"
                  :state="countryState"
                  :disabled="true"
                />
                <z-input
                  id="user-billing-zip-code"
                  v-model="mutableBillingData.zipcode"
                  class="flex-grow-1"
                  label="Código Postal"
                  :state="zipCodeState"
                  :disabled="disabled"
                />
              </div>

              <z-input
                id="user-billing-street"
                v-model="mutableBillingData.street"
                label="Calle"
                name="billing-street"
                :state="streetState"
                :disabled="disabled"
              />
              <div
                class="two-column-input d-flex justify-content-between flex-column flex-sm-row"
              >
                <z-input
                  id="user-billing-number"
                  v-model="mutableBillingData.numExt"
                  class="flex-grow-1"
                  label="No. Exterior"
                  :state="numExtState"
                  :disabled="disabled"
                />
                <z-input
                  id="user-billing-number-optional"
                  v-model="mutableBillingData.numInt"
                  class="flex-grow-1"
                  label="No. Interior (Opcional)"
                  :state="!disabled ? true : null"
                  :disabled="disabled"
                />
              </div>
              <z-select
                v-model="mutableBillingData.neighborhood"
                :options="neighborhoodOptions"
                :state="neighborhoodState"
                :disabled="disabled"
                label="Colonia"
              />

              <div
                class="two-column-input d-flex justify-content-between flex-column flex-sm-row"
              >
                <z-input
                  id="user-billing-city"
                  v-model="mutableBillingData.city"
                  class="flex-grow-1 "
                  label="Ciudad"
                  :disabled="!editAutocompleteInputs"
                />
                <z-input
                  id="user-billing-state"
                  v-model="mutableBillingData.state"
                  class="flex-grow-1 "
                  label="Estado"
                  :disabled="!editAutocompleteInputs"
                />
              </div>
            </div>
          </div>
        </b-form>
        <div
          v-if="
            $can(
              clientAccess.EDIT_CLIENT_BILLING_DATA,
              clientAccess.moduleName
            ) ||
              $can(
                accountAccess.EDIT_ACCOUNT_BASIC_INFO,
                accountAccess.moduleName
              ) ||
              $can(
                driverAccess.EDIT_DRIVER_BILLING_DATA,
                driverAccess.moduleName
              )
          "
          class="mt-5 w-100 d-flex flex-column flex-sm-row"
        >
          <z-button
            v-show="disabled"
            class="z-action w-auto mr-0 mr-sm-3"
            @click="disabled = !disabled"
          >
            Editar
          </z-button>

          <z-button
            v-show="!disabled"
            variant="secondary"
            class="z-action w-auto mr-0 mr-sm-3"
            @click="cancelEdit"
          >
            Cancelar
          </z-button>
          <z-button
            v-show="!disabled"
            :loading="isUpdatingBillingData"
            :disabled="disableSaveButton"
            class="z-action w-auto mt-3 mt-sm-0"
            @click="updateBillingData"
          >
            Guardar cambios
          </z-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import AccountAccess from "@/constants/access/accounts";
import ClientAccess from "@/constants/access/clients";
import DriverAccess from "@/constants/access/drivers";
import notifyMixin from "@/mixins/notify";
import validateStateMixin from "@/mixins/validateState";
import BillingData from "@/services/billing-data";
import Clients from "@/services/clients";
import ZSelect from "@zubut/common/src/components/ZSelect.vue";
import { validateEmail, validateName } from "@/utils/strings";
import { validateOnlyNumbers } from "@/utils/numbers";

export default {
  name: "CardDetailBillingData",

  components: { ZSelect },

  mixins: [notifyMixin("notifications"), validateStateMixin],

  props: {
    component: {
      type: String,
      required: true,
      validator: value => {
        return ["user", "account", "drivers"].indexOf(value) !== -1;
      }
    },
    clientId: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      default: ""
    },
    additional: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: true,
      clientAccess: ClientAccess,
      accountAccess: AccountAccess,
      driverAccess: DriverAccess,
      disabled: true,
      searched: false,
      neighborhoodOptions: [],
      billingData: {
        id: "",
        city: "",
        country: "México",
        numExt: "",
        numInt: "",
        state: "",
        street: "",
        zipcode: "",
        neighborhood: "",
        billingName: "",
        rfc: "",
        alternateEmail1: "",
        alternateEmail2: ""
      },
      mutableBillingData: {
        id: "",
        city: "",
        country: "",
        numExt: "",
        numInt: "",
        state: "",
        street: "",
        zipcode: "",
        neighborhood: "",
        billingName: "",
        rfc: "",
        alternateEmail1: "",
        alternateEmail2: ""
      },
      isUpdatingBillingData: false,
      message: "",
      isSuccess: false,
      hasError: false
    };
  },

  computed: {
    legalNameState() {
      return !this.disabled
        ? validateName(this.mutableBillingData.billingName)
          ? true
          : false
        : null;
    },

    rfcState() {
      return !this.disabled ? this.mutableBillingData.rfc.length >= 12 : null;
    },
    countryState() {
      return this.isRequired(this.mutableBillingData.country);
    },
    zipCodeState() {
      const zipCodeValidations = () => {
        const isOnlyNumbers = validateOnlyNumbers(
          this.mutableBillingData.zipcode
        );

        if (isOnlyNumbers && this.mutableBillingData.zipcode.length === 5)
          return true;
        else {
          return false;
        }
      };

      return !this.disabled ? zipCodeValidations() : null;
    },
    streetState() {
      return this.isRequired(this.mutableBillingData.street);
    },
    numExtState() {
      return this.isRequired(this.mutableBillingData.numExt);
    },
    neighborhoodState() {
      return this.isRequired(this.mutableBillingData.neighborhood);
    },
    safeAdditional() {
      if (this.additional == null || this.additional.alternateEmails == null)
        return {
          alternateEmails: []
        };
      return this.additional;
    },
    alternateEmail1() {
      if (this.safeAdditional.alternateEmails.length < 1) return "";
      return this.safeAdditional.alternateEmails[0];
    },
    alternateEmail2() {
      if (this.safeAdditional.alternateEmails.length < 2) return "";
      return this.safeAdditional.alternateEmails[1];
    },
    alternateEmail1State() {
      const alternateEmail1Validations = () => {
        const isValidEmail = this.isValidEmail(
          this.mutableBillingData.alternateEmail1
        );
        if (
          !isValidEmail ||
          (this.mutableBillingData.alternateEmail1.length > 0 &&
            this.mutableBillingData.alternateEmail1 === this.userEmail)
        )
          return false;
        return true;
      };

      return !this.disabled ? alternateEmail1Validations() : null;
    },
    alternateEmail1InvalidFeedback() {
      if (this.veeErrors.first("alternateEmail1")) return "Correo inválido";
      if (
        this.mutableBillingData.alternateEmail1.length > 0 &&
        this.mutableBillingData.alternateEmail1 === this.userEmail
      )
        return "El correo adicional no puede ser el mismo que el del usuario";
      return "";
    },
    alternateEmail2State() {
      const alternateEmail2Validations = () => {
        const isValidEmail = this.isValidEmail(
          this.mutableBillingData.alternateEmail2
        );
        if (
          !isValidEmail ||
          (this.mutableBillingData.alternateEmail1.length > 0 &&
            this.mutableBillingData.alternateEmail2.length > 0 &&
            this.mutableBillingData.alternateEmail1 ===
              this.mutableBillingData.alternateEmail2)
        )
          return false;
        if (
          this.mutableBillingData.alternateEmail2.length > 0 &&
          this.mutableBillingData.alternateEmail2 === this.userEmail
        )
          return false;
        return true;
      };

      return !this.disabled ? alternateEmail2Validations() : null;
    },
    alternateEmail2InvalidFeedback() {
      if (this.veeErrors.first("alternateEmail2")) return "Correo inválido";
      if (
        this.mutableBillingData.alternateEmail2.length > 0 &&
        this.mutableBillingData.alternateEmail2 === this.userEmail
      )
        return "El correo adicional no puede ser el mismo que el del usuario";
      if (
        this.mutableBillingData.alternateEmail1.length > 0 &&
        this.mutableBillingData.alternateEmail2.length > 0 &&
        this.mutableBillingData.alternateEmail1 ===
          this.mutableBillingData.alternateEmail2
      )
        return "El correo adicional no puede ser el mismo que el anterior";
      return "";
    },
    updatedEmails() {
      const emails = [];
      emails.push(this.mutableBillingData.alternateEmail1);
      emails.push(this.mutableBillingData.alternateEmail2);
      return emails;
    },
    disableSaveButton() {
      return (
        this.isUpdatingBillingData ||
        !this.legalNameState ||
        !this.rfcState ||
        !this.alternateEmail1State ||
        !this.alternateEmail2State ||
        !this.countryState ||
        !this.zipCodeState ||
        !this.streetState ||
        !this.numExtState ||
        !this.neighborhoodState
      );
    },
    missingBillingData() {
      return !this.billingData || this.billingData.billingName === "";
    },
    editAutocompleteInputs() {
      if (this.disabled) return false;
      return this.searched && this.neighborhoodOptions.length <= 0;
    },
    hasMessage: {
      get() {
        return this.hasError || this.isSuccess;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    }
  },

  watch: {
    additional: {
      immediate: true,
      handler() {
        this.resetBillingData();
      }
    },

    "mutableBillingData.zipcode": {
      handler(newVal, oldVal) {
        if (newVal.length > 4) {
          this.dispatchSearch();
        } else {
          if (oldVal.length > 4) {
            this.searched = false;
            this.clearAutocompleteInputs();
          }
        }
      }
    }
  },

  mounted() {
    this.dispatchSearch = _debounce(this.getInfoByZipCode, 700);
    this.getBillingData();
  },

  methods: {
    isRequired(inputValue) {
      if (inputValue === null) return !this.disabled ? false : null;
      return !this.disabled ? inputValue.length > 0 : null;
    },
    isValidEmail(email) {
      const isValidEmail = email.length === 0 ? true : validateEmail(email);
      return isValidEmail;
    },
    getInfoByZipCode() {
      const zipCode = this.mutableBillingData.zipcode;
      this.$store
        .dispatch("utils/getNeighborhoodByZipCode", zipCode)
        .then(res => {
          const isSameNeighborhood = res.neighborhoods.some(
            neighborhood => neighborhood === this.billingData.neighborhood
          );
          this.mutableBillingData.state = res.state;
          this.mutableBillingData.city = res.city;
          if (
            this.billingData.neighborhood != "" &&
            this.disabled &&
            !isSameNeighborhood
          ) {
            this.neighborhoodOptions = [
              this.billingData.neighborhood,
              ...res.neighborhoods
            ];
          } else {
            this.neighborhoodOptions = res.neighborhoods;
          }
        })
        .catch(err => {
          this.clearAutocompleteInputs();
        })
        .finally(() => (this.searched = true));
    },

    getBillingData() {
      this.loading = true;
      let where = {};
      switch (this.component) {
        case "user":
          where.clientId = this.clientId;
          break;
        case "account":
          where.accountId = this.clientId;
          break;
        case "drivers":
          where.driversId = this.clientId;
          break;
      }
      if (this.component === "drivers") {
        BillingData.getBillingData({ where })
          .then(res => {
            if (res.length > 0) {
              this.billingData = res[0];
              this.resetBillingData();
              this.neighborhoodOptions = this.billingData.neighborhood
                ? [this.billingData.neighborhood]
                : null;
            }
          })
          .catch(err => this.$captureError(err))
          .finally(() => {
            this.loading = false;
          });
      } else {
        Clients.getBillingData(this.clientId)
          .then(res => {
            if (res) {
              this.billingData = res;
              this.resetBillingData();
              this.neighborhoodOptions = this.billingData.neighborhood
                ? [this.billingData.neighborhood]
                : null;
            }
          })
          .catch(err => this.$captureError(err))
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateBillingData() {
      this.disabled = true;
      this.isUpdatingBillingData = true;
      const updatePromises = [
        this.$store.dispatch(`${this.component}/dispatchBillingData`, {
          clientId: this.clientId,
          data: this.mutableBillingData,
          billingDataClientId:
            this.component === "drivers"
              ? this.billingData.driversId
              : this.billingData.clientId
        })
      ];
      if (this.updatedEmails.length > 0 && this.component !== "drivers") {
        updatePromises.push(
          this.$store.dispatch(`user/updateAdditional`, {
            clientId: this.clientId,
            data: {
              alternateEmails: this.updatedEmails.filter(
                email => email.length > 0
              )
            }
          })
        );
      }
      Promise.all(updatePromises)
        .then(([updatedBillingData, updatedUser]) => {
          this.message = "Datos de facturación actualizados con exito";
          this.isSuccess = true;
          if (updatedBillingData) {
            this.billingData = updatedBillingData;
          }
          if (updatedUser) {
            this.$emit("updated-additional", updatedUser.additional);
          }
        })
        .catch(err => {
          this.resetBillingData();
          this.$captureError(err);
          this.hasError = true;
          this.message = err.message || "No se ha podido realizar el cambio";
        })
        .finally(() => {
          this.isUpdatingBillingData = false;
          this.$validator.reset();
        });
    },

    cancelEdit() {
      this.resetBillingData();
      this.disabled = true;
      this.searched = false;
    },
    resetBillingData() {
      this.searched = false;
      this.mutableBillingData.id = this.billingData.id;
      this.mutableBillingData.city = this.billingData.city;
      this.mutableBillingData.country = this.billingData.country || "México";
      this.mutableBillingData.state = this.billingData.state;
      this.mutableBillingData.street = this.billingData.street;
      this.mutableBillingData.zipcode = this.billingData.zipcode;
      this.neighborhoodOptions = this.billingData.zipcode
        ? this.neighborhoodOptions
        : [];
      this.mutableBillingData.neighborhood = this.billingData.neighborhood
        ? this.billingData.neighborhood
        : "";
      this.mutableBillingData.billingName = this.billingData.billingName;
      this.mutableBillingData.rfc = this.billingData.rfc;
      this.mutableBillingData.numExt = this.billingData.numExt || "";
      this.mutableBillingData.numInt = this.billingData.numInt || "";
      this.mutableBillingData.alternateEmail1 = this.alternateEmail1;
      this.mutableBillingData.alternateEmail2 = this.alternateEmail2;
    },
    clearAutocompleteInputs() {
      this.mutableBillingData.state = "";
      this.mutableBillingData.city = "";
      this.neighborhoodOptions = [];
    },

    updateRfc() {
      this.$store
        .dispatch("drivers/dispatchBillingData", {
          clientId: this.clientId,
          data: this.mutableBillingData
        })
        .then(() => {
          return true;
        })
        .catch(err => {
          this.$captureError(err);
          return false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$gap: 20px;

.card {
  color: $black;
  border: 1px solid $gainsboro;
}

.form-group {
  margin: 0;
}

.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

.two-column-input {
  gap: $gap;
}

.z-action {
  height: 40px;
  width: 100px;
  font-weight: 500;
}

.z-grid-page {
  display: grid;
  grid-gap: 2em;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;

  p:first-child {
    font-size: 14px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) {
  .z-grid-page {
    grid-gap: 4.375rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);

    .invoice-data {
      grid-column: 1;
    }

    .personal-data {
      grid-column: 2;
    }
  }
}
</style>
