<template>
  <div>
    <b-card class="p-2">
      <go-back
        v-show="!isLoading && driver"
        :title="title"
        :subtitle="subtitle"
        @back-action="$router.go(-1)"
      >
        <template #end>
          <buttons-layout>
            <z-button variant="secondary" @click="showMessageModal = true">
              Enviar Mensaje
            </z-button>
            <activity-button :id="id" type="driver" />
          </buttons-layout>
        </template>
      </go-back>
      <drivers-message-modal
        :show.sync="showMessageModal"
        :driver="driver"
      ></drivers-message-modal>
      <div>
        <div v-if="isLoading && !driver">
          <loading-spinner />
        </div>
        <div class="py-4">
          <z-tabs pills content-class="mt-3 p-0">
            <z-tab
              lazy
              title="Perfil"
              :active="!$route.query.tab || $route.query.tab === 'perfil'"
              @click="changeTab('perfil')"
            >
              <loading-spinner v-if="isLoading" />
              <driver-detail-profile v-else :user="driver" />
            </z-tab>
            <z-tab
              lazy
              title="Facturación"
              :active="$route.query.tab === 'facturacion'"
              @click="changeTab('facturacion')"
            >
              <loading-spinner v-if="isLoading" />
              <card-detail-billing-data
                v-else
                component="drivers"
                :client-id="id"
              />
            </z-tab>
            <z-tab
              v-if="
                $can(driverAccess.READ_DRIVER_SERVICES, driverAccess.moduleName)
              "
              lazy
              title="Servicios"
              :active="$route.query.tab === 'servicios'"
              @click="changeTab('servicios')"
            >
              <driver-detail-services />
            </z-tab>
            <z-tab
              lazy
              title="Admin"
              :active="$route.query.tab === 'admin'"
              @click="changeTab('admin')"
            >
              <loading-spinner v-if="isLoading" />
              <driver-detail-admin
                v-else
                :driver="driver"
                :satws-registered="satWSRegistered"
                @updated="getDriver"
              />
            </z-tab>
          </z-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import DriverAccess from "@/constants/access/drivers";
import lazyLoad from "@/app/components/lazyLoad";
import GoBack from "@zubut/common/src/components/GoBackTitle";
import CardDetailBillingData from "@/app/components/DetailProfile/CardDetailBillingData";
import ActivityButton from "@/app/components/ActivityButton";
import notifyMixin from "@/mixins/notify";
import DriverDetailProfile from "./DriverDetailProfile";
import DriversMessageModal from "@/app/components/DriversMessageModal/DriversMessageModal";
import Http from "@zubut/common/src/constants/http";
import ButtonsLayout from "@/app/layouts/ButtonsLayout.vue";
import ZTabs from "@zubut/common/src/components/ZTabs.vue";
import ZTab from "@zubut/common/src/components/ZTab.vue";

/* Lazy loaded components */
const DriverDetailAdmin = () =>
  lazyLoad(import("./DriverDetailAdmin/DriverDetailAdmin"));
const DriverDetailServices = () => lazyLoad(import("./DriverDetailServices"));

export default {
  components: {
    GoBack,
    DriverDetailAdmin,
    CardDetailBillingData,
    ActivityButton,
    DriverDetailServices,
    DriverDetailProfile,
    DriversMessageModal,
    ButtonsLayout,
    ZTabs,
    ZTab
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      driverAccess: DriverAccess,
      driver: null,
      isLoading: true,
      satWSRegistered: false,
      showMessageModal: false
    };
  },

  computed: {
    title() {
      if (this.driver) {
        return `${this.driver.name}`;
      }
      return "Usuario";
    },
    subtitle() {
      const driver = this.driver;
      if (driver && driver.lastLogin) {
        return `Último inicio de sesión ${formatISO(driver.lastLogin)}`;
      }
      return "No existe última sesión";
    },
    id() {
      return this.$route.params.id;
    },
    billingData() {
      return this.driver?.billingData;
    }
  },

  beforeMount() {
    this.getDriver();
  },

  methods: {
    changeTab(tab) {
      this.$router.replace({ name: "driverDetail", query: { tab } });
    },

    getDriver(isUpdatedByEmitEvent = false) {
      this.isLoading = isUpdatedByEmitEvent ? false : true;
      const driverId = this.$route.params.id;
      this.$store
        .dispatch("drivers/setSelectedDriver", driverId)
        .then(res => {
          this.driver = res;
          this.setSatWSRegistrationStatus(res.billingData?.rfc);
          this.isLoading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.isLoading = false;
          }
        });
    },

    setSatWSRegistrationStatus(rfc) {
      this.$store
        .dispatch("invoices/satWSRegistrationStatus", rfc)
        .then(status => {
          this.satWSRegistered = status.exists;
        })
        .catch(err => {
          this.satWSRegistered = false;
        });
    }
  }
};
</script>
